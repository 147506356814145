import Geocode from "react-geocode";
import { toast } from "react-toastify";
import { DEFAULTS_PROPS_MAP, SPACE_TYPE_IDS, SPV_INPUT_NAMES } from "constants/products/spv";
import { intlMessages, isDefined, isFieldDefined } from "services/util/auxiliaryUtils";
/* ******** START GMAP - SETTINGS || PROPS ******* */

let mainMap;
let mainGoogle;
let mainDrawingManager;
let mainPolygon;
let mainDrag;


//Init Map
export function INIT_MAP(
    mapProps,
    map,
    mapRef,
    setEvtListener,
    hasProductInputs,
    inputs,

) {

    const { google } = mapProps;
    //Set Map Object
    mainMap = map;
    // mapRef = map;
    //Set Google Object
    mainGoogle = google;
    //Set InfoWindow Object
    mainDrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
                google.maps.drawing.OverlayType.POLYGON,
            ],
        },
        map: mainMap,
    })



    //Set Tilt
    mainMap.setTilt(DEFAULTS_PROPS_MAP.mapOptions.tilt);
    // mainMap.setOptions(DEFAULTS_PROPS_MAP.mapOptions.tilt);

    // eventos do map
    handleOnAddlistenerMap(setEvtListener);

    //Quando acaba de desenhar um polígono
    mainGoogle.maps.event.addListener(mainDrawingManager, 'polygoncomplete', (polygon) => {

        //Quando o utilizador desenha um elemento que não é um polígono  
        let availableArea = mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());
        if (availableArea === 0) {
            polygon.setMap(null);
            return toast.error(intlMessages('page.spv.error.drawing'));
        }


        //Quando o polígono foi criado
        mainPolygon = polygon;
        polygon.setOptions({
            ref: mapRef,
            id: 1,
            editable: true,
            draggable: true,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
            strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
            fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
            fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
            strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
        });

        handleOnAddlistenerPolygon(polygon, setEvtListener);
        setEvtListener({
            evt: 'polygoncomplete',
            availableArea,
            geolocation: {
                ...getPolygonVerticesAndAvg(polygon)
            },
            fnc: 'INIT_MAP'
        });

        mainDrawingManager.setOptions({
            drawingMode: null
        });
    });

    mainDrawingManager.setMap(mainMap);

    if (hasProductInputs)
        mainMap.setZoom(DEFAULTS_PROPS_MAP.zoomIn);

    //#region createPoligon
    let polygonPoints = (inputs?.geolocation?.polygon) ? inputs?.geolocation?.polygon : [];
    if (polygonPoints.length > 0) {
        mainPolygon = new google.maps.Polygon({
            ref: mapRef,
            zIndex: 0,
            paths: polygonPoints,
            editable: true,
            draggable: true,
            strokeOpacity: DEFAULTS_PROPS_MAP.polygon.styles.strokeOpacity,
            strokeWeight: DEFAULTS_PROPS_MAP.polygon.styles.strokeWeight,
            fillOpacity: DEFAULTS_PROPS_MAP.polygon.styles.fillOpacity,
            fillColor: DEFAULTS_PROPS_MAP.polygon.styles.fillColor.selected,
            strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
        });

        //addListener
        handleOnAddlistenerPolygon(mainPolygon, setEvtListener);
    }

    //#endregion createPoligon
}



/**
 *  handleOnAddlistenerMap
 * @private
 * 
 * @param {*} polygonGroups 
 * @param {*} setEvtListener 
 */
function handleOnAddlistenerMap(setEvtListener) {

    //Click no mapa (sem usar)
    mainMap.addListener('click', () => { });

    mainMap.addListener('drag', () => { });

}

/**
 * handleOnAddlistenerPolygon
 * 
 * @see Gmap events https://developers.google.com/maps/documentation/javascript/events
 * @param {*} polygon 
 * @param {*} polygonGroups 
 */
function handleOnAddlistenerPolygon(
    polygon,
    // fnc
    setEvtListener
) {


    // Quando selecionamos o polygon
    mainGoogle.maps.event.addListener(polygon, 'click', () => { });

    //Quando começa a arrastar a zona
    polygon.addListener('dragstart', () => {
        mainDrag = true;
    });

    //Quando acaba de arrastar a zona
    polygon.addListener('dragend', () => {
        setEvtListener({
            evt: 'dragend',
            geolocation: {
                ...getPolygonVerticesAndAvg(polygon)
            },
            fnc: 'handleOnAddlistenerPolygon'
        });
        mainDrag = false;
    });

    //Quando é adicionado uma novo vertex no polygon selecionado
    mainGoogle.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        // get availableArea
        let availableArea = mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());

        setEvtListener({
            evt: 'insert_at',
            availableArea,
            geolocation: {
                ...getPolygonVerticesAndAvg(polygon)
            },
            fnc: 'handleOnAddlistenerPolygon'
        });

    });

    //Quando editamos o polygon (selecionado com o click do mouse)
    mainGoogle.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        // get availableArea
        if (!mainDrag) {
            let availableArea = mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());

            setEvtListener({
                evt: 'set_at',
                availableArea,
                geolocation: {
                    ...getPolygonVerticesAndAvg(polygon)
                },
                fnc: 'handleOnAddlistenerPolygon',
            });
        }

    });

    //Quando criamos uma nova vertex e clicamos o undo "retroceder" no polygon selecionado
    mainGoogle.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        // get availableArea
        let availableArea = mainGoogle.maps.geometry.spherical.computeArea(polygon.getPath());
        setEvtListener({
            evt: 'remove_at',
            availableArea,
            geolocation: {
                ...getPolygonVerticesAndAvg(polygon)
            },
            fnc: 'handleOnAddlistenerPolygon',
        });
    });

    // add polygon on Map 
    polygon.setMap(mainMap);
}

/**
 * setZoomIn
 * 
 * @param {*} divContent 
 */
export function setZoomIn() {
    if (isDefined(mainMap)) mainMap.setZoom(DEFAULTS_PROPS_MAP.zoomIn);
}


function getPolygonVerticesAndAvg(polygon) {
    let polygonPoints = [];
    let avg = {
        lat: 0.0,
        lng: 0.0,
    };
    // get coordenate points
    polygon.getPaths().forEach(function (element) {
        element.forEach(function (el) {
            let points = { lat: el.lat(), lng: el.lng() };
            //sum lat and lng
            avg.lat = avg.lat + el.lat();
            avg.lng = avg.lng + el.lng();
            //save points
            polygonPoints.push(points);
        });
    });

    //avg lat and lng
    avg.lat = (avg.lat / polygonPoints.length);
    avg.lng = (avg.lng / polygonPoints.length);

    return {
        polygon: polygonPoints,
        lat: avg.lat,
        lng: avg.lng
    };
}


/**
 * toggleDrawingMode
 * 
 * @param {*} drawPolygon 
 * @param {*} setDrawPolygon 
 */
export function handleToggleDrawingMode(drawPolygon, setDrawPolygon) {

    if (drawPolygon) {
        mainDrawingManager.setOptions({
            drawingMode: null
        });
    } else {
        mainDrawingManager.setOptions({
            drawingMode: mainGoogle.maps.drawing.OverlayType.POLYGON,
            polygonOptions: {
                strokeColor: DEFAULTS_PROPS_MAP.polygon.styles.strokeColor.selected,
            }
        });
    }

    setDrawPolygon(!drawPolygon);
}

export function handleResetDrawArea() {
    if (!!mainPolygon) {
        mainPolygon.setMap(null);
    }
}


/* ******** END GMAP - SETTINGS || PROPS ******* */

export function handleProductlInputsSPV(data) {

    for (let key in data) {

        if (!data?.[SPV_INPUT_NAMES.AQS_SELECTED]) {
            // delete inputs
            if ([
                SPV_INPUT_NAMES.RANGE_ID,
                SPV_INPUT_NAMES.SOLUTION,
                SPV_INPUT_NAMES.REDIRECT_LINK,
            ].includes(key)) {
                delete data[key];
                continue;
            }
        }

        // converter objs
        if (SPV_INPUT_NAMES.GEOLOCATION === key) {
            data[key] = {
                lat: data[key].lat,
                lng: data[key]?.lon ? data[key]?.lon : data[key]?.lng,//conversao temporaria
                address: data[key].address,
                polygon: data[key].polygon,
                locality: data[key]?.locality ?? null,
                region: data[key]?.region ?? null,
                postal_code: data[key]?.postal_code ?? null,
            }
            delete data[key].lon;
            continue;
        }


        //inputs sem alterar   
        if ([
            SPV_INPUT_NAMES.SPACE_TYPE_ID,
            SPV_INPUT_NAMES.AVAILABLE_AREA,
            SPV_INPUT_NAMES.EQUIPMENTS,
            SPV_INPUT_NAMES.GEOLOCATION,
            SPV_INPUT_NAMES.CONTACT_DETAILS,
            SPV_INPUT_NAMES.COMMON_SPACES,
            SPV_INPUT_NAMES.JOINT_OWNERS_INFO,
            SPV_INPUT_NAMES.SHOW_FORM,
            SPV_INPUT_NAMES.ACTUAL_INVERTER_POWER
        ].includes(key)) {
            continue;
        }

        //inputs aqs bundle 
        if ([
            SPV_INPUT_NAMES.OLD_EQUIPMENT_TYPE_ID,
            SPV_INPUT_NAMES.ENERGY_SOURCE_ID,
            SPV_INPUT_NAMES.NUM_OCCUPANTS,
        ].includes(key)) {
            continue;
        }

        //inputs typeOf boolean
        if (![SPV_INPUT_NAMES.SOLUTION].includes(key)) {
            data[key] = `${data[key]}`;
            continue;
        }
    }

    // SPACE_TYPE_ID 
    if (!isDefined(data?.[SPV_INPUT_NAMES.SPACE_TYPE_ID])) {
        data[SPV_INPUT_NAMES.SPACE_TYPE_ID] = SPACE_TYPE_IDS.HOUSING;
    }

    if (isDefined(data?.[SPV_INPUT_NAMES.CONTACT_DETAILS]?.nif)) {
        data[SPV_INPUT_NAMES.NIF] = data[SPV_INPUT_NAMES.CONTACT_DETAILS].nif;
    }

    return data;
}



export function getGeoCodeInfo(geoCodeAllData) {

    const isArray = Array.isArray(geoCodeAllData);

    let geoCodeData = isArray ? geoCodeAllData[0] : geoCodeAllData;

    //#region locality
    let findLocalitybylvl2 = isArray ?
        geoCodeAllData.find(item => item.address_components.find(cmp => cmp.types.includes('administrative_area_level_2'))?.long_name)
        : null;
    let locality = findLocalitybylvl2?.address_components?.find(item => item.types.includes('administrative_area_level_2'))?.long_name ||
        geoCodeData.address_components.find(cmp => cmp.types.includes('locality') && cmp.types.includes('political'))?.long_name;
    //#endregion locality

    let region = geoCodeData.address_components.find(item => item.types.includes('administrative_area_level_1'))?.long_name;
    let country = geoCodeData.address_components.find(cmp => cmp.types.includes('country') && cmp.types.includes('political'))?.long_name;
    let postal_code = geoCodeData.address_components.find(item => item.types.includes('postal_code'))?.long_name;
    let { lat, lng } = geoCodeData.geometry.location;
    let address = geoCodeData.formatted_address;

    return {
        lat: typeof lat === 'function' ? lat() : lat,
        lng: typeof lng === 'function' ? lng() : lng,
        address,
        locality,
        region,
        postal_code,
        country
    }
}


export function buildInputs(inputs) {
    if (Array.isArray(inputs.contracted_energy_tariff)) {
        let newContractedEnergyTariff = {
            simple: '',
            empty: '',
            full: '',
            peak: '',
            super_vazio: '',
            fora_vazio: '',
        };

        inputs.contracted_energy_tariff.forEach(element => {
            switch (element.id) {
                case 1:
                    newContractedEnergyTariff.empty = element.value ?? '';
                    break;
                case 2:
                    newContractedEnergyTariff.full = element.value ?? '';
                    break;
                case 3:
                    newContractedEnergyTariff.peak = element.value ?? '';
                    break;
                case 4:
                    newContractedEnergyTariff.super_vazio = element.value ?? '';
                    break;
                case 5:
                    newContractedEnergyTariff.fora_vazio = element.value ?? '';
                    break;
                default:
                    break;
            }
        });
        inputs.contracted_energy_tariff = newContractedEnergyTariff;
    }

    if (Array.isArray(inputs.monthly_invoice_by_hourly_period)) {
        let newMonthlyInvoiceByHourlyPeriod = {
            simple: '',
            empty: '',
            full: '',
            peak: '',
            super_vazio: '',
            fora_vazio: '',
        };
        inputs.monthly_invoice_by_hourly_period.forEach(element => {
            switch (element.id) {
                case 1:
                    newMonthlyInvoiceByHourlyPeriod.empty = element.value ?? '';
                    break;
                case 2:
                    newMonthlyInvoiceByHourlyPeriod.full = element.value ?? '';
                    break;
                case 3:
                    newMonthlyInvoiceByHourlyPeriod.peak = element.value ?? '';
                    break;
                case 4:
                    newMonthlyInvoiceByHourlyPeriod.super_vazio = element.value ?? '';
                    break;
                case 5:
                    newMonthlyInvoiceByHourlyPeriod.fora_vazio = element.value ?? '';
                    break;
                default:
                    break;
            }
        });
        inputs.monthly_invoice_by_hourly_period = newMonthlyInvoiceByHourlyPeriod;
    }

    return inputs;
}

export function buildCommonSpacesData(data) {
    let newData = {};
    let newContractedEnergyTariff = [];
    let newMonthlyInvoiceByHourlyPeriod = [];
    /*
        id  description
        ----------------
        1   Vazio
        2   Cheia
        3   Ponta
        4   Super Vazio
        5   Fora Vazio 
    */
    switch (parseInt(data.hourly_option_id)) {
        case 2: //BI_HOURLY
            newContractedEnergyTariff = [
                { id: 1, value: data.contracted_energy_tariff.empty },
                { id: 5, value: data.contracted_energy_tariff.fora_vazio }
            ];
            if (parseInt(data.option_id) === 3) {//Consumo mensal por período horário
                newMonthlyInvoiceByHourlyPeriod = [
                    { id: 1, value: data.monthly_invoice_by_hourly_period.empty },
                    { id: 5, value: data.monthly_invoice_by_hourly_period.fora_vazio }
                ];
            }
            break;
        case 3: //THREE_HOUR
            newContractedEnergyTariff = [
                { id: 1, value: data.contracted_energy_tariff.empty },
                { id: 2, value: data.contracted_energy_tariff.full },
                { id: 3, value: data.contracted_energy_tariff.peak }
            ];
            if (parseInt(data.option_id) === 3) {//Consumo mensal por período horário
                newMonthlyInvoiceByHourlyPeriod = [
                    { id: 1, value: data.monthly_invoice_by_hourly_period.empty },
                    { id: 2, value: data.monthly_invoice_by_hourly_period.full },
                    { id: 3, value: data.monthly_invoice_by_hourly_period.peak }
                ];
            }
            break;
        case 4: //FOUR_HOUR
            newContractedEnergyTariff = [
                { id: 1, value: data.contracted_energy_tariff.empty },
                { id: 2, value: data.contracted_energy_tariff.full },
                { id: 3, value: data.contracted_energy_tariff.peak },
                { id: 4, value: data.contracted_energy_tariff.super_vazio }
            ];
            if (parseInt(data.option_id) === 3) {//Consumo mensal por período horário
                newMonthlyInvoiceByHourlyPeriod = [
                    { id: 1, value: data.monthly_invoice_by_hourly_period.empty },
                    { id: 2, value: data.monthly_invoice_by_hourly_period.full },
                    { id: 3, value: data.monthly_invoice_by_hourly_period.peak },
                    { id: 4, value: data.monthly_invoice_by_hourly_period.super_vazio }
                ];
            }
            break;
        default:
            break;
    }

    newData = {
        ...data,
        contracted_energy_tariff: data.hourly_option_id !== 1 ? newContractedEnergyTariff : data.contracted_energy_tariff,
        monthly_invoice_by_hourly_period: data.hourly_option_id !== 1 && parseInt(data.option_id) === 3 ? newMonthlyInvoiceByHourlyPeriod : data.monthly_invoice_by_hourly_period,
        contracted_power_tariff: isFieldDefined(data.contracted_power_tariff) ? data.contracted_power_tariff : null
    }
    return newData;
}

export async function checkGeolocationData(data) {
    let newData = { ...data };
    let needUpdateData = false;

    for (const key in data) {
        if (!isDefined(data[key])) {
            needUpdateData = true;
        }
    }

    if (needUpdateData) {
        Geocode.setApiKey(DEFAULTS_PROPS_MAP.settings.apiKey);
        await Geocode.fromAddress(`${data.lat}, ${data.lng}`).then(
            response => {
                let geoPlace = response.results?.[0];
                newData = {
                    ...newData,
                    ...getGeoCodeInfo(geoPlace)
                };

            },
            error => {
                console.error(error);
            }
        );
    }

    return newData;
}